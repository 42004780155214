body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

canvas {
  display: block;
  flex: 1;
}
